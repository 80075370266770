
import { Vue, Options } from 'vue-class-component';
import debounce from 'lodash-es/debounce';
import Calendar from 'dayjs/plugin/calendar';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  BaseIcon,
  ButtonLink,
  BasePagination,
  BaseTextInput,
  DataTable,
  BasePopover,
  SpecialityButton,
  PopoverButton,
  PopoverLink,
  ActionModal
} from '@/lib/components';
import { PaginatedResponse, Patient } from '@/models';
import { OrganisationPatientService } from '@/services/api';
import { getDobFromISOString, getNhsNumberFromPatient } from '@/helpers/patient.helper';
import { sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(localizedFormat);

@Options({
  props: {
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseIcon,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    ButtonLink,
    BasePagination,
    BaseTextInput,
    DataTable,
    PopoverButton,
    ActionModal
  }
})
export default class PatientListPage extends Vue {
  organisationId!: string;
  unWatchRoute = null;
  loading = true;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();

  rows: Patient[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;
  request: CancelTokenSource | null = null;
  viewRemovePatientModal = false;
  selectedPatientId = '';
  sexOptions = sexOptions();
  genderOptions = genderOptions();
  $customer?: string;
  notificationStore = useNotificationStore();

  get orgPatientService(): OrganisationPatientService {
    return new OrganisationPatientService(this.organisationId);
  }

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'last_name';
  }

  get removeModalActions() {
    return [
      {
        label: this.$t('platform.patient.remove') as string,
        color: 'danger',
        onClick: () => this.removePatient(this.selectedPatientId)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => this.closeRemovePatientModal()
      }
    ];
  }

  unmounted() {
    if (this.unWatchRoute) {
      this.unWatchRoute();
    }
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  created() {
    dayjs.extend(Calendar);
    this.$watch('organisationId', async () => {
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      this.search = String(this.$route.query.search || '');
      await this.fetchPatients();
    });
    this.search = String(this.$route.query.search || '');
    this.fetchPatients();

    this.unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchPatients();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  showColumns() {
    const columns = [
      { name: 'last_name', label: this.$t('platform.patient.patient'), sortable: true },
      { name: 'date_of_birth', label: this.$t('platform.patient.born'), sortable: true },
      { name: 'last_activity', label: this.$t('platform.common.last-activity') },
      { name: 'deceased_at' },
      { name: 'actions', label: '', align: 'right' }
    ];
    return this.$customer === 'UHB' ? columns.filter((column) => column.name !== 'last_activity') : columns;
  }

  async fetchPatients() {
    this.loading = true;
    this.progressStore.startProgress();

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include: 'patient',
        page: this.page,
        sort: this.sort,
        'filter[patientType]': 'major',
        ...(this.search ? { 'filter[search]': this.search } : {})
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.orgPatientService.index(requestConfig)) as PaginatedResponse<Patient[]>;
      this.request = null;
      this.rows = response.data.map((patient: Patient) => {
        patient.nhs = getNhsNumberFromPatient(patient);
        if (patient.deceased_at) {
          return { ...patient, isMuted: true };
        }
        return patient;
      });

      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.patient.fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  formatDateToLocale(date: string, type: string) {
    return this.$d(new Date(date), type);
  }

  formatDob(date: string) {
    return this.$d(getDobFromISOString(date), 'shortMonth');
  }

  showRemovePatientModal(patientId: string) {
    this.viewRemovePatientModal = true;
    this.selectedPatientId = patientId;
  }

  closeRemovePatientModal(patientId?: string) {
    if (patientId) {
      this.fetchPatients();
    }
    this.viewRemovePatientModal = false;
    this.selectedPatientId = '';
  }

  dispatchErrorMessage() {
    this.notificationStore.addErrorNotification({
      title: this.$t('platform.patient.remove-error')
    });
  }

  async removePatient(patientId: string) {
    try {
      await this.orgPatientService.delete(patientId);
    } catch (error) {
      this.dispatchErrorMessage();
    } finally {
      this.closeRemovePatientModal(patientId);
    }
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find((option) => option.value === patient.gender);
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }
}
